.img-panel {
  height: 100%;
  overflow: auto;
}
.img-panel .el-collapse-item__header .header-icon {
  margin-left: 5px;
  color: #f56c6c;
  font-size: 20px;
}
.img-panel .img-card-default {
  width: 150px;
  height: 150px;
  margin: 5px;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
}
.img-panel .img-card-checked {
  border: 1px solid #f56c6c;
}
.img-panel .img-body {
  position: relative;
  display: inline-block;
  overflow: hidden;
}
